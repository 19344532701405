import React from 'react'

import Form from '../form'

import Input from '../input'

import styles from './style.module.scss'

import Select from '../select'

import Button from '../button'

import ChartImage from './ChartImage'

import PieChart from 'react-minimal-pie-chart'

import * as primary from '../../styles/primary-pallete'

import cn from 'classnames'

const DATA = [
    {
        title: '73 successful campaigns',
        value: 73,
        color: primary.blue4
    },
    {
        title: '60 projects',
        value: 15,
        color: primary.blue6
    },
    {
        title: '29000 leads',
        value: 100,
        color: primary.blue8
    },
    {
        title: 'Hundreds of thousands of saving',
        value: 100,
        color: primary.blue5
    }
]

const SECTIONS = [
    {
        title: 'For Developers',
        description:
            'Get the right exposure for your project and your brand by utilizing any or all of our products, starting by a banner to a full page wrapping and site branding, or target lead generation by a sponsored project or developer page or going for a campaign for a project mini site. Bank on years of real estate marketing experience.'
    },
    {
        title: 'For Real Estate Professional',
        description:
            'Get known, get noticed, stand out and get the best leads with our variety of tailor made products to fit your needs and offer you the reach you want. Be precise about your target and pick the right product to get you there. Be it a page sponsorship to dominate a project or an area or a special campaign to get leads that you can convert to transactions and impact your bottom line.'
    },
    {
        title: 'For Property Owner',
        description:
            'Give your property it’s own website. Be it a trophy asset, a high end property, or a multi unit building get in touch to get the right exposure and have control over your property sale or lease process and price.'
    },
    {
        title: 'For Service Provider',
        description:
            'Be noticed by your best target segment, property owners and tenants. Be part of our community.'
    },
    {
        title: 'For Mortgage Provider',
        description:
            'Whether it is sponsoring the mortgage page or getting to our community through our other channels, it makes sense to you reaching your target market through the most effective reference property portal.'
    }
]

export default () => {
    return (
        <div className={styles.listPropertyForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12">
                        <h1>Why you should advertise with us</h1>
                        <div className="row middle-xs">
                            <div className="col-xs-6 col-sm-3">
                                <PieChart data={DATA} />
                            </div>
                            <div className="col-xs-6 col-sm-6 col-sm-offset-2">
                                <ul className={styles.chartDetails}>
                                    {DATA.map(d => (
                                        <li>
                                            <span
                                                style={{
                                                    backgroundColor: d.color
                                                }}
                                            />
                                            {d.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className={cn('row', styles.sections)}>
                            {SECTIONS.map(section => (
                                <div className="col-xs-6 col-sm-4">
                                    <div className={styles.section}>
                                        <h3>{section.title}</h3>
                                        <p>{section.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <h3>
                            We are specialised in real estate marketing and lead
                            generation
                        </h3>
                        <p>
                            We (not robots but humans) at Propertyeportal are a
                            bunch of strong digital marketing pros embarked on a
                            journey as we realized the possibility of digital
                            intelligence. But why digital marketing? If not for
                            our digital presence, you have no chance of finding
                            us here. Yes it is the era of digital media and
                            would continue to be so in the coming days. What as
                            brand you get out of digital presence? For it is the
                            process of attracting targeted audiences online that
                            will spell the difference between a successfully
                            thriving business – and a failed one. Even if you
                            receive tons of daily traffic to your website, they
                            would not amount to anything unless they convert to
                            leads or sales. In the digital arena where business
                            and commerce are heading to, Digital Marketing tools
                            and techniques provide business owners the best
                            chances for competition, survival and even business
                            growth.
                        </p>
                        <h3>
                            Digital Marketing Is More Cost-Effective than
                            Traditional Marketing
                        </h3>
                        <p>
                            The chart below that confirms how digital marketers
                            get better Cost-Per-Lead (CPL) compared to other
                            marketing channels. Source: Hubspot
                        </p>
                        <div className="row center-xs">
                            <div className="col-xs-6 col-sm-4">
                                <ChartImage />
                            </div>
                        </div>
                        <p>
                            Dubai is on the forefront of technology. In a smart
                            city like Dubai, a lot of work gets done on smart
                            phones. So reach your audience, perform and grow. To
                            reach larger global audience in a creative and
                            compelling way FEATURE your property today in
                            www.propertyeportal.com. Feel free to Contact us for
                            a free appraisal and consultation to demonstrate how
                            we can assist you in your conversions. We have run
                            tens of successful campaigns for projects of Emaar,
                            meeras, dp, mag, sobha and many others for projects
                            locally and internationally. Our clients are major
                            real estate developers and brokerages. Contact us
                            today to get details about our purpose built
                            campaigns, our campaigns are built with the
                            conversion in mind.{' '}
                            <a href="mailto: advertise@propertyeportal.com">
                                advertise@propertyeportal.com
                            </a>
                        </p>
                        <div className="col-xs-12 col-sm-6">
                            <Form
                                className={styles.form}
                                errorMessageClass={styles.errorMessage}
                                id="form"
                                requiredFields={['name', 'email', 'phone']}
                            >
                                <Input type="text" label="Name" required />
                                <Input type="email" label="Email" required />
                                <Input type="text" label="Phone" required />
                                <Input type="text" label="Designation" />
                                <Input type="text" label="Company name" />
                                <Input type="text" label="Website" />
                                <Select label="Interested in">
                                    <option value="Search Engine Marketing (SEM)">
                                        Search Engine Marketing (SEM)
                                    </option>
                                    <option value="Social Media Marketin (SMM)">
                                        Social Media Marketin (SMM)
                                    </option>
                                    <option value="Market Reach">
                                        Market Reach
                                    </option>
                                    <option value="Photography & Videography">
                                        Photography & Videography
                                    </option>
                                </Select>
                                <Input type="textarea" label="Message" />

                                <Button className={styles.marginTopPlus}>
                                    Send
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
